import React from 'react';

const HeroSingle = pageTitle => (
    <section className="innerbanner">
        <div className="container">
            <h1>{pageTitle.pageTitle}</h1>
        </div>
    </section>
);

export default HeroSingle;
